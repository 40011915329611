import React, { useCallback, useContext, useRef, useState } from "react";
import { AdContext } from "../../context/adContext";
import { mobile } from "../../utilities/detection";
import AdFreeLink from "./adFreeLink";
import { useSelector } from "react-redux";
import { adsSelector } from "../../redux/slices/loginSlice";
import { adData } from "../../config";

export default function Adhesion() {
  const adDiv = useRef(null);
  const { adhesionDiv, adhesionCreated } = useContext(AdContext);
  const ads = useSelector(adsSelector);
  const adId = adData.divIds.adhesion;
  const [open, setOpen] = useState(true);

  const handleAdDiv = useCallback((node) => {
    if (node) {
      adDiv.current = adhesionDiv;
      node.appendChild(adhesionDiv);

      if (!adhesionCreated.current) {
        adhesionCreated.current = true;
      }
    }
  }, []);

  if (ads && mobile && open) {
    return (
      <div data-testid="adhesion-ad" className="a-adhesion">
        <button className="a-adhesion-close" onClick={() => setOpen(false)} aria-label="close">
          ×
        </button>
        <div id={`${adId}-container`} className={`a-adhesion-container`} ref={handleAdDiv} />
        <div className="a-cta">
          <AdFreeLink />
        </div>
      </div>
    );
  }

  return null;
}
